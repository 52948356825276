<template>
  <div class="out">
    <Top />
    <div class="content">
      <Transaction-navbar :title="lang.v.addMethod" />
      <el-select
        v-model="selectValue"
        :placeholder="lang.v.setPayment"
        class="select"
      >
        <el-option
          v-for="item in options"
          :key="item?.id"
          :label="item?.name"
          :value="item?.id"
        />
      </el-select>
      <el-input
        v-model="textarea"
        class="input"
        :rows="7"
        type="textarea"
        placeholder="Please input"
        :show-word-limit="true"
        maxlength="300"
      />

      <Button
        plain
        :style="isInput ? 'background: #ED931E' : 'background: #aaa'"
        class="btn"
        @click="isInput ? submit() : ''"
      >{{ lang.v.saveInfo }}</Button
      >
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { Getpayway } from '@/api/fetcher';
import { useRouter, useRoute } from 'vue-router';
import dataCenter from '@/dataCenter';
import Top from '@/components/Top/top.vue';
import { lang } from '@/lang';
const router = useRouter();
const textarea = ref('');
const selectValue = ref('');
const options = reactive([]);
const isInput = ref(false);
const route = useRoute();
watch([textarea, selectValue], () => {
  if (textarea.value && selectValue) {
    isInput.value = true;
  } else {
    isInput.value = false;
  }
});

onMounted(async () => {
  const data = await Getpayway();
  Object.assign(options, data);
});

const submit = () => {
  const existing = dataCenter.payStatus.data.find(
    item => item.id == route.query.id || 0
  );
  const payWayName = options.find(item => item.id == selectValue.value)?.name;
  if (existing) {
    existing.payWay = selectValue.value;
    existing.payWayName = payWayName;
    existing.payDesc = textarea.value;
  } else {
    dataCenter.payStatus.data = [
      ...dataCenter.payStatus.data,
      {
        payWay: selectValue.value,
        payWayName: payWayName,
        payDesc: textarea.value,
        id: route.query.id || 0,
      },
    ];
  }
  router.go(-1);
};

//挂载
onMounted(() => {
  if (route.query.id) {
    const existing = dataCenter.payStatus.data.find(
      item => item.id == route.query.id
    );
    if (existing) {
      selectValue.value = existing.payWay;
      textarea.value = existing.payDesc;
    }
  }
});
</script>

<style lang="less" scoped>
.content {
  box-sizing: border-box;
  width: 100%;
  padding: 0.14rem;
  display: flex;
  flex-direction: column;
}
.select {
  width: 100%;
  margin: 0.14rem 0 0.1rem;
}
.input {
  width: 100%;
  margin-bottom: 0.12rem;
}
:deep(.el-select) {
  border: 0rem !important;
}
.btn {
  width: 100%;
  border-radius: 0.23rem;
  color: #fff;
}
</style>
